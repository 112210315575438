<template>
	<div class="team">

		<LandingpageHeader activeTab="teams"></LandingpageHeader>

		<!-- HEADER -->

		<div class="landingpage__header">

			<div class="landingpage__header__text">
				<h1>Das sind unsere<br><span>Hero</span>-Teams!</h1>
				<p>Wir unterstützen Teams &amp; Vereine mit unserer hohen Expertise im Bereich Training &amp; Ernährung, damit sie auf und neben dem Platz ihr volles Potenzial erreichen können.</p>
			</div>

			<div class="landingpage__header__image">
				<img src="@/assets/landingpage/teams-header.jpg" srcset="@/assets/landingpage/teams-header@2x.jpg 2x" alt="Teams SportlerPlus">
			</div>

		</div>


		<!-- TEAM -->

		<div class="teams">

			<div class="teams__item" v-for="ht in heroteams" v-bind:key="ht.id">
				
				<div class="teams__item__image">
					<img :src="'https://app.sportlerplus.com' + ht.image.meta.download_url" :alt="ht.name">
				</div>

				<div class="teams__item__text">
					<img :src="'https://app.sportlerplus.com' + ht.logo.meta.download_url" :alt="'Logo ' + ht.name">
					<div class="teams__item__category">
						<!-- TODO @Marco: Hier muss aus dem Backend die Kategorie/Sportart gezogen werden inkl. korrektem Icon -->
						<img :src="sportsTypeIcon(ht.sports_type_icon)" :alt="sportsTypeName(ht.sports_type_icon)">
						{{ sportsTypeName(ht.sports_type_icon) }}
					</div>
					<h2>{{ ht.name }}</h2>
					<p v-html="ht.description"></p>
					<a :href="ht.link" target="_blank" class="teams__item__social teams__item__social--website">
						{{ ht.link_text }}
						<img src="@/assets/ic_16_extern.svg" alt="External Link">
					</a>
				</div>

			</div>
			
		</div>

		<!-- Weitere Teams -->
		<div class="content-block">
			<div class="content-block__content">
				<h3>Weitere Teams & Kooperationen</h3>
				<p>Neben den Hero-Teams vertrauen auch die folgenden Teams &amp; Vereine auf unsere Expertise rund um Training & Ernährung.</p>

				<div class="content-block__slider">
					<splide :options="sliderOptionsTeams" :slides="teampartner">
						<splide-slide v-for="tp in teampartner" v-bind:key="tp.id">
							<a :href="tp.link" target="_blank" class="team__card">
								<div class="team__card__image">
									<img :src="'https://app.sportlerplus.com' + tp.logo.meta.download_url" :alt="'Logo ' + tp.name">
								</div>
								<div class="team__card__text">
									<div class="team__card__title">
										{{ tp.name }}
									</div>
									<div class="team__card__meta">
										{{ tp.sports_type }}
									</div>
								</div>
							</a>
						</splide-slide>
					</splide>
				</div>

			</div>
		</div>

		<!-- TEAMBOX -->
		<Teambox></Teambox>

		<LandingpageFooter></LandingpageFooter>

		<ContactModal :programmeType="'hallOfFame'"></ContactModal>

	</div>
</template>

<script>
import axios from 'axios';

import LandingpageHeader from "@/components/LandingpageHeader.vue";
import LandingpageFooter from "@/components/LandingpageFooter.vue";
import Teambox from "@/components/Teambox.vue";
import ContactModal from "@/components/ContactModal.vue";
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
	name: 'Team',
	metaInfo() {
        if (this.page == null) { return {meta: []} }
		return { 
            title: this.page.title,
			meta: [
                {
                    vmid: "description",
                    property: "description",
                    content: "Wir unterstützen Teams aus verschiedenen Sportarten für die beste PErformance vor, während und nach dem Spiel/ Training.",
                },
				{
                    vmid: "og:title",
					property: "og:title",
					content: this.page.title
				},
				{
                    vmid: "og:site_name",
					property: "og:site_name",
					content: "SportlerPlus"
				},
				{
                    vmid: "og:type",
					property: "og:type",
					content: "website"
				}
			]
		}
	},
	components: { LandingpageHeader, LandingpageFooter, Teambox, ContactModal, Splide, SplideSlide },
	mounted() {
		this.init();
	},
	data() {
		return {
			heroteams: null,
			teampartner: null,
			sliderOptionsTeams: {
				arrows: false,
				pagination: false,
				perPage: 4,
				gap: '24px',
				padding: '16px',
				breakpoints: {
					768: {
						perPage: 1,
						drag: true,
						pagination: true,
						gap: '8px',
					}
				}
			},
		}
	},
	methods: { 
		init() {
			let apiUrl = this.$store.getters.getApiUrl;
			// retrieve partner teams from API via axios
			axios.get(apiUrl + "teampartner").then((response) => {
				this.teampartner = response.data.items;
				// sort partner teams by sort_order field
				this.teampartner.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);
			});
			// retrieve hero teams from API via axios
			axios.get(apiUrl + "heroteams").then((response) => {
				this.heroteams = response.data.items;
				// sort hero teams by sort_order field
				this.heroteams.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);
			});
		},
		scrollToTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				/* TODO: Smooth Transition doesn't work */
				behavior: 'smooth'
			});
		},
		openContactModal() {
			this.$modal.show("contactModal");
		},
		sportsTypeName(sti) {
			return {
				3: "Handball",
				4: "Fussball",
				7: "Volleyball",
				8: "American Football",
				9: "Basketball",
			}[sti];
		},
		sportsTypeIcon(sti) {
			if (sti == 3) {
				return require("@/assets/landingpage/ic_24_team_basketball.svg");
			}
			else if (sti == 4) {
				return require("@/assets/landingpage/ic_24_team_fussball.svg");
			}
			else if (sti == 7) {
				return require("@/assets/landingpage/ic_24_team_volleyball.svg");
			}
			else if (sti == 8) {
				return require("@/assets/landingpage/ic_24_team_football.svg");
			}
			else if (sti == 9) {
				return require("@/assets/landingpage/ic_24_team_basketball.svg");
			}
			
		}
	},
}
</script>

<style lang="scss" scoped>

	input {
		text-align: left !important;
	}

	@media screen and (min-width: 768px) {
		.vm-modal {
			max-width: 640px !important;
		}
	}

</style>

<style lang="scss">

	.team {
		position: relative;
		background: $colorWhite;
		overflow: hidden;

		nav {
			z-index: 2;

			@media screen and (min-width: 768px) {
				background: none;
				position: relative;
			}
		}

		&__card {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			background: $colorWhite;
			box-shadow: 0 8px 16px 0 rgba($colorBlack, .04), 0 0 0 1px rgba($colorBlack, .04);
			border-radius: 8px;
			text-decoration: none;
			margin-bottom: 24px;
			transition: all .2s ease-in-out;

			&:hover {
				@media screen and (min-width: 768px) {
					box-shadow: 0 12px 24px 0 rgba($colorBlack, .08), 0 0 0 1px rgba($colorBlack, .04);
				}
			}

			&__wrapper {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				margin: 56px 0 -96px;
			}

			&__image {
				display: flex;
				padding: 56px 0 40px;

				img {
					width: 128px;
					height: 128px;
				}
			}

			&__text {
				display: flex;
				width: 100%;
				flex-direction: column;
				justify-content: center;
				text-align: center;
				box-shadow: 0 -1px 0 0 rgba($colorBlack, .08);
				padding: 14px 0;
			}

			&__title {
				font-size: 17px;
				font-weight: 700;
				letter-spacing: -0.35px;
				color: $colorBlack;
			}

			&__meta {
				font-size: 14px;
				font-weight: 500;
				color: rgba($colorBlack, .48);
				margin-top: -2px;
			}
		}
		
	}

	.teams {
		position: relative;
		z-index: 2;
		max-width: 1088px;
		margin: 16px auto 32px;
		padding: 0 16px;

		@media screen and (min-width: 768px) {
			padding: 0 24px;
			margin: 16px auto 96px;
		}

		&__item {
			display: flex;
			align-items: center;
			flex-direction: column;

			@media screen and (min-width: 768px) {
				flex-direction: row;
				margin-bottom: 0;
			}

			&:not(:last-child) {
				margin-bottom: 64px;
			}

			&:nth-child(even) {

				@media screen and (min-width: 768px) {
					flex-direction: row-reverse;
				}

				.teams__item__text {
					@media screen and (min-width: 768px) {
						padding: 0 48px 0 0;
					}

					@media screen and (min-width: 1024px) {
						padding: 0 96px 0 0;
					}
				}

				.teams__item__image {
					justify-content: flex-start;
				}
			}

			&__image {
				width: calc(100% + 32px);
				margin: 0 -16px 24px;
				display: flex;
				justify-content: flex-end;

				@media screen and (min-width: 768px) {
					width: 50%;
					margin: 0;
				}

				img {
					width: 100%;
					height: 100%;
					box-shadow: 0 8px 8px 0 rgba($colorBlack, .08);

					@media screen and (min-width: 1024px) {
						width: auto;
						height: auto;
						box-shadow: 0 16px 24px 0 rgba($colorBlack, .12);
					}
				}
			}

			&__text {
				position: relative;
				width: 100%;
				padding-top: 24px;

				@media screen and (min-width: 768px) {
					width: 50%;
					padding-left: 48px;
				}

				@media screen and (min-width: 1024px) {
					padding-left: 96px;
				}

				h2 {
					margin-bottom: 17px;

					@media screen and (min-width: 1024px) {
						font-size: 40px;
						line-height: 52px;
						letter-spacing: -0.83px;
						margin-bottom: 29px;
					}
				}

				> img {
					position: absolute;
					top: -24px;
					left: -8px;
					width: 80px;
					height: 80px;
					transform: translate(0, -50%);

					@media screen and (min-width: 768px) {
						top: 50%;
						left: 0;
						right: inherit;
						transform: translate(-50%, -50%);
					}

					.teams__item:nth-child(even) & {
						@media screen and (min-width: 768px) {
							right: 0;
							left: inherit;
							transform: translate(50%, -50%);
						}
					}
				}
			}

			&__category {
				display: flex;
				align-items: center;
				font-size: 14px;
				font-weight: 600;
				line-height: 28px;
				color: rgba($colorBlack, .48);
				margin-bottom: -3px;

				@media screen and (min-width: 768px) {
					margin-bottom: 9px;
					color: $colorPrimary;
					text-transform: uppercase;
					font-weight: 700;
				}

				img {
					display: none;
					width: 24px;
					height: 24px;
					margin-right: 8px;

					@media screen and (min-width: 768px) {
						display: flex;
					}
				}
			}

			&__social {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-weight: 700;
				color: $colorPrimary !important;
				text-decoration: none;
				background: rgba($colorPrimary, .08);
				border-radius: 8px;
				transition: all .2s ease-in-out;
				margin-top: 8px;

				&:hover {
					background: rgba($colorPrimary, .12);
				}

				&--instagram {
					padding: 10px 16px 10px 10px;

					img {
						margin-right: 12px;
					}
				}

				&--website {
					padding: 12px 16px;

					img {
						margin-left: 6px;
					}
				}
			}
		}
	}

	/* Display text inputs left aligned */
	.team{
		input {
			text-align: left !important;
		}
	}
</style>